import React from "react";
import { MenuItem } from "../constants/restaurant_menu";

interface MenuItemProps extends MenuItem {}

export const MenuItemV2 = (props: MenuItemProps) => {
  const {
    title,
    price,
    description,
    subtitle,
    additionalItem,
    additionalItemPrice,
  } = props;

  const subtitleToShow = subtitle
    ? !!title
      ? ` / ${subtitle}`
      : subtitle
    : "";

  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex flex-grow-1">
        <div className="flex-grow-1 pe-4">
          <h3 className="h5 gold-text mb-0 text-uppercase font-medium menu-item-title">
            {title}
            {subtitle && (
              <span className="ml-3 font-thin ml-2">{subtitleToShow}</span>
            )}
          </h3>
          <p className="menu-desc gold-text font-light mb-0">{description}</p>
        </div>

        <h3 className="h4 font-thin gold-text ml-5 mr-3">{price}</h3>
      </div>

      {additionalItem && (
        <div className="d-flex flex-grow-1 align-items-center">
          <div className="flex-grow-1 pe-4">
            <p className="menu-desc gold-text font-light mb-0">
              {additionalItem}
            </p>
          </div>

          <h3 className="h4 font-thin gold-text ml-5 mr-3 mb-0">
            {additionalItemPrice}
          </h3>
        </div>
      )}
    </div>
  );
};
