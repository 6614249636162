import React from "react";
import MenuTitle from "./MenuTitle";
import { MenuItemV2 } from "./MenuItemV2";
import { MenuInterface } from "../constants/restaurant_menu";
import { useTranslation } from "react-i18next";
import { Translations } from "../i18n/translationKeys";

const ROW_CUTOFF_INDEX = 3;

type ShowMenuProps = {
  menu: any;
  title: string;
};

export const ShowMenu = (props: ShowMenuProps) => {
  const { menu, title } = props;

  const { t } = useTranslation();
  const leftMenu = menu.slice(0, ROW_CUTOFF_INDEX);
  const rightMenu = menu.slice(ROW_CUTOFF_INDEX);

  return (
    <div className="center-container pb-5" style={{ textAlign: "start" }}>
      <h1 className="display-2 text-center gold-text title-font my-3-lg mt-3">
        {title}
      </h1>

      <div
        className="container-fluid row mt-4 mx-auto"
        style={{ paddingBottom: "10vh" }}
      >
        <div className="col-12 col-md-5 offset-md-1">
          {leftMenu.map((a: MenuInterface) => (
            <>
              <MenuTitle title={a.title} />
              {a.items.map((i) => (
                <div>
                  <MenuItemV2
                    title={i.title}
                    description={
                      // @ts-ignore
                      t(Translations[i?.title?.replace(/ +/g, "")]) as string
                    }
                    subtitle={i?.subtitle}
                    price={i.price}
                    // @ts-ignore
                    additionalItem={t(i?.additionalItem) as string}
                    additionalItemPrice={i?.additionalItemPrice}
                  />
                </div>
              ))}
            </>
          ))}
        </div>

        <div className="col-12 col-md-4 offset-md-1">
          {rightMenu.map((a: MenuInterface) => (
            <>
              <MenuTitle title={a.title} />
              {a.items.map((i) => (
                <div>
                  <MenuItemV2
                    title={i.title}
                    description={
                      (a.type !== "cocktails" &&
                        (t(
                          // @ts-ignore
                          Translations[i?.title?.replace(/ +/g, "")]
                        ) as string)) ||
                      ""
                    }
                    subtitle={
                      (a.type === "cocktails" &&
                        (t(
                          // @ts-ignore
                          Translations[i?.title?.replace(/ +/g, "")]
                        ) as string)) ||
                      // @ts-ignore
                      (t(i?.subtitle?.replace(/ +/g, "")) as string)
                    }
                    price={i.price}
                  />
                </div>
              ))}
            </>
          ))}
          <div style={{ marginTop: "10vh" }}>
            <p className="menu-desc gold-text font-thin text-center">
              {t(Translations.FOOTER.DESCRIPTION)}
            </p>
            <p className="menu-desc gold-text font-thin text-center">
              {t(Translations.FOOTER.MANAGER)}
            </p>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
