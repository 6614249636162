import React, { useState } from "react";
import "./App.css";
import Main from "./components/Main";
import Home from "./components/Home";
import Overlay from "./components/Overlay";
import Contact from "./components/Contact";
import { ShowMenu } from "./components/ShowMenu";
import { restaurantMenu } from "./constants/restaurant_menu";
import { takeawayMenu } from "./constants/takeaway_menu";

function App({ initialRoute }: { initialRoute: number }) {
  const [displayOverlay, setOverlay] = useState(false);
  const [menu, setMenu] = useState(initialRoute);
  const [didChange, setDidChange] = useState(false);

  return (
    <div className="app">
      <Main
        displayOverlay={displayOverlay}
        setOverlay={setOverlay}
        menu={menu}
        setMenu={setMenu}
        didChange={didChange}
        setDidChange={setDidChange}
      />

      {menu === 0 ? (
        <Home />
      ) : menu === 1 ? (
        <ShowMenu title="Menu" menu={restaurantMenu} />
      ) : menu === 2 ? (
        <ShowMenu title="Takeaway" menu={takeawayMenu} />
      ) : menu === 3 ? (
        <Contact />
      ) : null}

      <Overlay
        display={displayOverlay}
        setMenu={setMenu}
        setOverlay={setOverlay}
        setDidChange={setDidChange}
        didChange={didChange}
      />
    </div>
  );
}

export default App;
