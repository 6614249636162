import { Translations } from "../i18n/translationKeys";
import { MenuInterface } from "./restaurant_menu";

export const takeawayMenu: MenuInterface[] = [
  {
    title: "Antipasti",
    items: [
      {
        title: "MELANZANA FRITTA",
        price: "9",
      },
      {
        title: "VITELLO TONNATO",
        price: "14,5",
      },
      {
        title: "SICILIAN POLPETTE",
        price: "9,5",
      },
      {
        title: "CROCCE DI GALLO",
        price: "8,5",
      },
    ],
  },
  {
    title: "Wood Fired Pizza",
    items: [
      {
        title: "PRINCIPESSA MARGHERITA",
        price: "12",
        additionalItem: Translations.PRINCIPESSAMARGHERITA_ADDITIONAL,
        additionalItemPrice: "+4",
      },
      {
        title: "ANABELLA",
        price: "15,5",
      },
      {
        title: "GUSTINA",
        price: "16,5",
      },
      {
        title: "GIOCONDA",
        price: "13",
      },
      {
        title: "ISABELLA",
        price: "14",
      },
      {
        title: "BEATRICE",
        price: "17",
      },
      {
        title: "FRANCESCA",
        price: "17",
      },
      {
        title: "GLORIA",
        price: "15",
      },
      {
        title: "LOLITA",
        price: "16",
      },
      {
        title: "ROSINA",
        price: "10,5",
        additionalItem: Translations.ROSINA_ADDITIONAL,
        additionalItemPrice: "+4",
      },
    ],
  },
  {
    title: "Pasta Fresca",
    items: [
      {
        title: "ALESSANDRA ALLA CHITARRA",
        price: "12",
      },
      {
        title: "CARLOTTA TORTIGLIONI",
        price: "13",
        additionalItem: Translations.CARLOTTATORTIGLIONI_ADDITIONAL,
        additionalItemPrice: "+2",
      },
      {
        title: "GIOLINA ALLA CHITARRA",
        price: "14,5",
      },
      {
        title: "GALILEA TORTELLI",
        price: "17",
      },
      {
        title: "LUDOVICA MALFADINE",
        price: "16",
      },
      {
        title: "ANTONELLA ALLA CHITARRA",
        price: "17",
      },
      {
        title: "VALENTINA ALLA CHITARRA",
        price: "15",
      },
      {
        title: "CHIARA PAPPARDELLE",
        price: "16",
      },
      {
        title: "GABRIELLA GNOCCHI RICOTTA",
        price: "14",
      },
    ],
  },
  {
    title: "Insalata",
    items: [
      {
        title: "BURRATA PANZANELLA",
        price: "13",
      },
      {
        title: "JULIUS CEASAR",
        price: "13,5",
      },
      {
        title: "RUCOLA",
        price: "10,5",
      },
      {
        title: "SPINACI DI FAGIOLI",
        price: "12,5",
      },
    ],
  },
  {
    title: "Dolci",
    items: [
      {
        subtitle: "TIRAMISU",
        price: "9",
      },
      {
        subtitle: "LUNA",
        price: "14",
      },
      {
        subtitle: "TORTA LIMONE",
        price: "9",
      },
      {
        subtitle: "RISOTTO DOLCE",
        price: "10",
      },
      {
        subtitle: "",
        price: "",
      },
      {
        subtitle: "ESPRESSO",
        price: "3",
      },
      {
        subtitle: "AFFOGATO ESPRESSO",
        price: "6",
      },
    ],
  },
  {
    title: "Birra",
    items: [
      {
        subtitle: "PERONI 330ML",
        price: "5",
      },
      {
        subtitle: "VIOLA LAGER 750ML",
        price: "19",
      },
    ],
  },
  {
    title: "Organic Italian Beverages",
    items: [
      {
        title: "GALVANINA PREMIUM SOFT DRINKS",
      },
      {
        subtitle: Translations.ARANCIATA,
        price: "4,3",
      },
      {
        subtitle: Translations.LIMONATA,
        price: "4,3",
      },
      {
        subtitle: Translations.MANDARINO,
        price: "4,3",
      },
      {
        subtitle: Translations.GASSOSA,
        price: "4,3",
      },
      {
        subtitle: Translations.AQUA_1000,
        price: "3",
      },
      {
        subtitle: Translations.AQUA_250,
        price: "3",
      },
      {
        subtitle: Translations.AQUA_750,
        price: "4",
      },
      {
        subtitle: Translations.COCACOLA,
        price: "3",
      },
    ],
  },
];
